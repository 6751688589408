import { KYC_STATUS, ORDER_ACTION, ORDER_EXPIRY, ORDER_EXPIRY_MAPPING, ORDER_TYPE, USAGE_POLICIES } from './constants'

export type Maybe<T> = T | null | undefined

export type UsagePolicyKey = (typeof USAGE_POLICIES)[keyof typeof USAGE_POLICIES]

export type UsagePolicy = {
    versionId: string
    slug: string
    disclaimer: string
}

export type Address = {
    address: string
    id: string
}

export type AddressDetails = {
    addressPrefix?: string | null
    streetNumber: string
    streetName: string
    streetType?: string
    city: string
    state: string
    postCode: string
    country: string
}

export type AddressType = 'residential' | 'postal'

export type AddressFormSchema = {
    residential: AddressDetails
    differentPostalAddress?: boolean
    postal: {
        addressPrefix?: string
        streetNumber?: string
        streetName?: string
        streetType?: string
        city?: string
        state?: string
        postCode?: string
        country?: string
    }
}

export interface Name {
    salutation: string
    firstName: string
    middleName?: string | null
    lastName: string
}

export interface ContactDetails {
    salutation: string
    firstName: string
    middleName: string | null
    lastName: string
    dateOfBirth: string
    occupation: string
    mobile: string
}
export type UpdateAddressesPayload = {
    addresses: {
        residentialAddress: AddressDetails
        postalAddress: AddressDetails
    }
}

export interface User extends ContactDetails {
    addresses?: {
        residentialAddress: AddressDetails
        postalAddress: AddressDetails
    }
    survey?: {
        investmentPurpose: string
        expectedTradeFrequency: string
        expectedTradeAmount: string
    }
    emailId: string
    userId: string
    createdAt: string
}

export interface InitKYC {
    entityId: string
    token: string
}

export type KYCStatus = {
    status: keyof typeof KYC_STATUS
    canInitiateKyc: boolean
}

export type SignupForm = {
    email: string
    password: string
}

export type LoginForm = {
    email: string
    password: string
}

export type InstrumentPrices = {
    lastPrice?: number
    closingPrice: number
    buyPrice: number
    percentageChange: number
    pointsChange: number
    sellPrice: number
    status: string
}

export type InstrumentT = {
    securityName: string
    securityCode: string
    description: string
    priceInfo: InstrumentPrices
}

export type InstrumentSearchRecordT = {
    securityName: string
    securityCode: string
}

export type InstrumentWithCampaignMetadataT = InstrumentT & {
    campaignDeadline?: string
}

export type TopMarketDataResponse = {
    page: number
    pageSize: number
    totalCount: number
    companies: InstrumentT[]
}

export type HistoricalPricesRangePreset = '1D' | '1W' | '1M' | '6M' | '1Y' | 'YTD' | '3Y' | '5Y' | 'ALL'

export type PriceDataPoint = {
    close: number
    low?: number
    high?: number
    open?: number
    date: string
    volume?: number
}
export interface EquityT extends Omit<InstrumentT, 'marketCapitalisation'> {
    statistics: {
        marketCapitalisation: number
        volume: number
        peRatio: number
        dividendYield?: string
        dayOpenedAt: number
        dayClosedAt?: number // only after trading close time
        dayRange: [number, number]
        yearRange: [number, number]
    }
    about: {
        ceo: string
        sector: string
        description: string
        history: string
        industry: string
        foundedYear?: string
        webAddress: string
        employees?: number
    }
    securityCode: string
}

export type CampaignStatus = {
    label: string
    description: string
    pillColour: string
}

export type TargetCompanyMetadata = {
    code: string
    campaignDeadline: string
    name: string
    milestones: {
        title: string
        date: string
    }[]
}

export type CampaignT = {
    campaignId: string
    heroImage: {
        url: string
        alt?: string
    }
    title: string
    goals?: string
    description?: string
    targetCompaniesAsxCodes: string[]
    targetCompaniesMetadata?: TargetCompanyMetadata[]
    followers: number
    followersGoal: number
    pledgesGoal: number
    webflowId: string
    countdownDate?: string
    displayOrder?: number
    sixPickSecurityCode: string
    sixPickExplanation: string
    status: CampaignStatus
    onSpotlight?: boolean
}

export type OrderPayload = {
    requestId: string
    securityCode: string
    units: number
    requestedPrice?: number
    orderType: 'MARKET_TO_LIMIT' | 'LIMIT'
    validity:
        | 'VALID_TILL_CANCEL'
        | 'VALID_TILL_EOD'
        | 'VALID_FOR_DAYS_30'
        | 'VALID_FOR_DAYS_7'
        | 'VALID_FOR_DAYS_15'
        | 'VALID_FOR_DAYS_30'
}

export type TradingAccountStatus = 'SUBMITTED' | 'APPROVED' | 'REJECTED'
export type TradingAccountSubmissionData = {
    firstName: string
    middleName: string
    lastName: string
}
export type TradingAccountDetails = {
    accountNumber: string
    hin: string
    status: TradingAccountStatus
    submissionData: string
}

export type OrderAction = keyof typeof ORDER_ACTION
export type OrderType = keyof typeof ORDER_TYPE

export type OrderExpiry = keyof typeof ORDER_EXPIRY

export type orderValidity = keyof typeof ORDER_EXPIRY_MAPPING

export enum orderStatus {
    REQUESTED = 'REQUESTED',
    'IN MARKET' = 'IN MARKET',
    COMPLETE = 'COMPLETE',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}

export type OrderT = {
    orderId: string
    companyName: string
    orderSide: string
    orderType: string
    units: number
    securityCode: string
    validity: orderValidity
    status: orderStatus
    createdAt: string
    expiresOn: string
    orderCompletionPrice?: number
    requestedPrice?: number
}

export type HoldingT = {
    securityCode: string
    name: string
    units: number
    averagePrice?: string
    cost?: number
    lastPrice: number
    currentValue: number
    totalChangeValue?: number
    totalChangePercent?: number
    dailyChangeValue?: number
    dailyChangePercent?: number
}

export type ListOrdersResponse = {
    range: {
        from: string
        to: string
    }
    count: number
    orders: OrderT[]
}

export type ListHoldingsResponse = {
    holdings: HoldingT[]
    totalValue: number
    hin: string
}

export type ListUserPledgesResponse = {
    campaignId: string
    securityCode: string
}[]
