import { SecurityAvatar } from '@/securities/securityAvatar.tsx'
import { PriceDiff } from './PriceDiff'
import PrecisionAwarePriceLabel from '@/common/models/precsionAwarePriceLabel.tsx'

export function SecuritySnapshot({
    securityCode,
    securityName,
    price,
    changeInPriceYTD,
}: {
    securityCode: string
    securityName: string
    price: number
    changeInPriceYTD: number
    historicalPrices: any
}) {
    return (
        <div className="w-[388px] gap-3.5 flex border rounded-[5px] border-[#d5d8d3] p-6 bg-background h-full">
            <div className="w-11 h-11">
                <SecurityAvatar securityCode={securityCode} height={11} width={11} />
            </div>

            <div className="flex-1 min-w-0">
                <div className="flex gap-1 w-full mb-1">
                    <div className="flex-1 text-black text-base font-semibold leading-snug">{securityCode}</div>
                    <div className="text-black leading-snug text-base font-bold">{`${PrecisionAwarePriceLabel.of(price)}`}</div>
                </div>

                <div className="flex gap-2.5 w-full">
                    <div className="flex-1 min-w-0 truncate font-medium text-[#57534E] text-sm leading-tight whitespace-nowrap overflow-hidden text-ellipsis">
                        {securityName}
                    </div>
                    <div className="flex gap-[2px] text-sm text-[#57534E] font-normal min-w-12">
                        {!isNaN(changeInPriceYTD) && (
                            <>
                                <PriceDiff value={changeInPriceYTD} label={`${Math.abs(changeInPriceYTD)}%`} />
                                YTD
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
