import { FC, ReactNode } from 'react'

import { EquityT, Maybe } from '@/common/types'
import { H3 } from '@/common/ui/h3'
import { inMillionsOrBillions } from '@/common/utils/utils'
import PrecisionAwarePriceLabel from '@/common/models/precsionAwarePriceLabel.tsx'

type KeyStatisticItemProps = {
    label: string
    value: ReactNode
}
const FinancialProfileItem: FC<KeyStatisticItemProps> = ({ label, value }) => {
    return (
        <li className="flex space-x-1 items-end last:border-none justify-between px-2 py-3 border-b border-neutral-300">
            <span className="text-sm text-left">{label}</span>
            <strong className="text-sm font-semibold text-right">{value}</strong>
        </li>
    )
}

type Props = {
    statistics: Maybe<EquityT['statistics']>
    // dayRange: Maybe<[number, number]>
    yearRange: Maybe<[number, number]>
}
export const FinancialProfile: FC<Props> = ({ statistics, yearRange }) => {
    if (!statistics) return
    const hasData =
        statistics.dayOpenedAt ||
        yearRange ||
        statistics.marketCapitalisation ||
        statistics.volume ||
        statistics.peRatio ||
        statistics.dividendYield
    if (!hasData) return <div>No financial data available. Please check back later.</div>

    return (
        <aside className="flex flex-col gap-8">
            <div>
                <H3 className="">Key Statistics</H3>
                <div className="w-full bg-[#F9F9F9] rounded py-3">
                    <ul className="w-full px-4 rounded-xl bg-gray-100">
                        {statistics.dayOpenedAt && (
                            <FinancialProfileItem
                                label="Open"
                                value={`${PrecisionAwarePriceLabel.of(statistics.dayOpenedAt)}`}
                            />
                        )}
                        {/* {dayRange && (
                        <FinancialProfileItem
                            label="Day Range"
                            value={`${PrecisionAwarePriceLabel.of(dayRange[0])} - ${PrecisionAwarePriceLabel.of(dayRange[1])}`}
                        />
                    )} */}
                        {yearRange && (
                            <FinancialProfileItem
                                label="52 week range"
                                value={`${PrecisionAwarePriceLabel.of(yearRange[0])} - ${PrecisionAwarePriceLabel.of(yearRange[1])}`}
                            />
                        )}
                        {statistics.marketCapitalisation && (
                            <FinancialProfileItem
                                label="Market Cap"
                                value={`$${inMillionsOrBillions(statistics.marketCapitalisation)}`}
                            />
                        )}
                        {statistics.volume && (
                            <FinancialProfileItem label="Avg. volume" value={inMillionsOrBillions(statistics.volume)} />
                        )}
                        {statistics.peRatio && statistics.peRatio > 0 && (
                            <FinancialProfileItem label="P/E ratio" value={statistics.peRatio} />
                        )}
                        {statistics.dividendYield && (
                            <FinancialProfileItem label="Dividend yield" value={statistics.dividendYield} />
                        )}
                    </ul>
                </div>
            </div>
        </aside>
    )
}
