import { SVGProps } from 'react'
export const TradingBlankState = (props: SVGProps<SVGSVGElement>) => (
    <svg width="200" height="193" viewBox="0 0 200 193" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_9450_187503)">
            <path
                d="M151.16 102.605C158.304 98.4112 167.823 97.5348 174.705 102.15C166.114 104.842 158.748 110.498 153.911 118.117C152.057 121.062 150.299 124.532 147.005 125.63C144.956 126.313 142.668 125.892 140.722 124.952C138.776 124.011 137.108 122.591 135.463 121.186L134.938 121.064C138.912 113.777 144.016 106.8 151.16 102.605Z"
                fill="#F0F0F0"
            />
            <path
                d="M174.632 102.307C167.801 101.787 160.981 103.44 155.14 107.033C153.869 107.784 152.723 108.731 151.746 109.84C150.829 110.951 150.225 112.289 149.997 113.713C149.757 115.037 149.665 116.42 149.11 117.662C148.816 118.294 148.392 118.857 147.866 119.313C147.341 119.769 146.725 120.108 146.06 120.308C144.415 120.851 142.674 120.768 140.974 120.627C139.087 120.471 137.129 120.279 135.321 120.984C135.103 121.069 134.983 120.723 135.202 120.638C138.346 119.411 141.718 120.796 144.947 120.213C146.454 119.94 147.888 119.211 148.631 117.811C149.281 116.587 149.377 115.162 149.608 113.819C149.81 112.426 150.349 111.103 151.178 109.968C152.096 108.812 153.203 107.822 154.451 107.04C157.235 105.233 160.284 103.877 163.487 103.021C167.137 102.024 170.93 101.661 174.702 101.948C174.936 101.966 174.863 102.325 174.632 102.307Z"
                fill="white"
            />
            <path
                d="M156.954 105.765C156.486 104.699 156.411 103.502 156.743 102.386C157.074 101.27 157.789 100.309 158.761 99.6733C158.958 99.5454 159.165 99.8469 158.968 99.9749C158.062 100.565 157.395 101.46 157.089 102.5C156.783 103.54 156.857 104.655 157.298 105.645C157.394 105.86 157.049 105.979 156.954 105.765Z"
                fill="white"
            />
            <path
                d="M150.029 113.026C152.231 113.908 154.683 113.924 156.897 113.072C157.116 112.987 157.236 113.333 157.017 113.417C154.711 114.3 152.159 114.278 149.87 113.355C149.651 113.267 149.812 112.938 150.029 113.026Z"
                fill="white"
            />
            <path
                d="M167.072 102.504C167.167 102.955 167.372 103.374 167.669 103.725C167.965 104.076 168.344 104.347 168.771 104.515C168.99 104.6 168.83 104.929 168.612 104.844C168.141 104.657 167.723 104.356 167.395 103.97C167.066 103.583 166.837 103.121 166.727 102.624C166.713 102.579 166.717 102.529 166.738 102.486C166.759 102.442 166.795 102.409 166.84 102.391C166.885 102.376 166.936 102.379 166.979 102.4C167.022 102.421 167.056 102.459 167.072 102.504Z"
                fill="white"
            />
            <path
                d="M177.564 130.132C177.426 130.083 177.288 130.034 177.147 129.986C175.283 129.332 173.369 128.832 171.425 128.489C171.275 128.46 171.123 128.433 170.972 128.409C166.279 127.633 161.483 127.739 156.828 128.721C154.976 129.114 153.156 129.647 151.385 130.317C148.939 131.242 146.36 132.44 143.827 132.498C143.562 132.507 143.298 132.499 143.035 132.476L134.837 121.717C134.831 121.683 134.822 121.651 134.816 121.617L134.469 121.203C134.558 121.162 134.651 121.121 134.74 121.08C134.791 121.056 134.845 121.035 134.896 121.011C134.931 120.995 134.966 120.98 134.997 120.965C135.008 120.959 135.02 120.954 135.029 120.951C135.06 120.935 135.09 120.924 135.118 120.91C135.638 120.682 136.161 120.457 136.686 120.236C136.688 120.234 136.688 120.234 136.692 120.235C140.697 118.557 144.876 117.2 149.127 116.523C149.255 116.503 149.385 116.481 149.517 116.465C151.435 116.171 153.374 116.034 155.314 116.057C156.376 116.073 157.437 116.142 158.492 116.266C161.218 116.59 163.877 117.343 166.37 118.497C171.339 120.797 175.521 124.668 177.423 129.743C177.471 129.873 177.518 130.001 177.564 130.132Z"
                fill="#F0F0F0"
            />
            <path
                d="M177.407 130.213C172.266 125.669 165.828 122.868 159.01 122.206C157.545 122.037 156.062 122.101 154.617 122.396C153.218 122.729 151.933 123.432 150.897 124.431C149.911 125.344 149.009 126.392 147.82 127.048C147.206 127.375 146.531 127.568 145.837 127.615C145.144 127.661 144.449 127.56 143.798 127.318C142.159 126.757 140.819 125.638 139.546 124.498C138.133 123.233 136.685 121.896 134.819 121.367C134.593 121.303 134.705 120.955 134.931 121.019C138.177 121.939 140.039 125.084 142.967 126.569C144.333 127.262 145.916 127.546 147.349 126.878C148.602 126.293 149.533 125.213 150.523 124.281C151.52 123.291 152.743 122.561 154.086 122.154C155.512 121.787 156.99 121.665 158.456 121.795C161.762 122.035 165.01 122.795 168.081 124.047C171.593 125.457 174.84 127.46 177.679 129.969C177.855 130.124 177.582 130.367 177.407 130.213Z"
                fill="white"
            />
            <path
                d="M161.216 122.289C161.482 121.156 162.141 120.154 163.074 119.463C164.008 118.773 165.156 118.437 166.313 118.518C166.36 118.521 166.404 118.543 166.435 118.579C166.467 118.614 166.483 118.661 166.481 118.708C166.479 118.756 166.459 118.8 166.424 118.833C166.39 118.866 166.345 118.884 166.297 118.883C165.22 118.807 164.151 119.119 163.283 119.764C162.415 120.409 161.805 121.345 161.563 122.401C161.511 122.631 161.164 122.517 161.216 122.289Z"
                fill="white"
            />
            <path
                d="M151.336 123.903C152.565 125.938 154.513 127.433 156.792 128.09C157.018 128.155 156.906 128.503 156.68 128.439C154.31 127.75 152.286 126.19 151.011 124.07C150.89 123.867 151.215 123.702 151.336 123.903Z"
                fill="white"
            />
            <path
                d="M171.251 125.799C171.058 126.216 170.97 126.675 170.996 127.134C171.023 127.594 171.162 128.039 171.403 128.431C171.526 128.632 171.201 128.797 171.078 128.598C170.814 128.164 170.661 127.672 170.631 127.164C170.6 126.657 170.694 126.149 170.904 125.687C170.921 125.641 170.953 125.604 170.996 125.582C171.039 125.56 171.088 125.555 171.134 125.569C171.18 125.584 171.218 125.616 171.24 125.66C171.262 125.703 171.266 125.753 171.251 125.799Z"
                fill="white"
            />
            <path
                d="M12.0642 168.256C12.9023 159.991 17.8106 151.756 25.5816 148.899C22.6818 157.451 22.889 166.758 26.1667 175.172C27.4425 178.412 29.1976 181.883 28.1388 185.201C27.4801 187.266 25.7922 188.872 23.8882 189.895C21.9842 190.918 19.8573 191.433 17.757 191.938L17.3493 192.293C13.8304 184.773 11.2261 176.521 12.0642 168.256Z"
                fill="#F0F0F0"
            />
            <path
                d="M25.6656 149.053C21.2193 154.284 18.528 160.788 17.974 167.642C17.8286 169.116 17.9155 170.603 18.2315 172.049C18.5846 173.448 19.3045 174.726 20.3162 175.751C21.2405 176.726 22.2983 177.616 22.9704 178.799C23.3055 179.41 23.5081 180.085 23.5651 180.78C23.622 181.475 23.5319 182.174 23.3008 182.832C22.7673 184.486 21.6737 185.848 20.5582 187.143C19.3196 188.581 18.0104 190.055 17.5121 191.936C17.4517 192.164 17.1029 192.057 17.1632 191.829C18.0302 188.557 21.1334 186.64 22.5679 183.678C23.2372 182.295 23.4957 180.702 22.8077 179.275C22.2061 178.026 21.1161 177.108 20.1718 176.129C19.1701 175.143 18.4242 173.927 17.999 172.585C17.6107 171.16 17.4668 169.678 17.5736 168.205C17.7613 164.883 18.4682 161.611 19.6683 158.51C21.0184 154.962 22.9631 151.673 25.4182 148.784C25.5703 148.605 25.8166 148.875 25.6656 149.053Z"
                fill="white"
            />
            <path
                d="M18.022 165.425C16.889 165.175 15.8813 164.53 15.1788 163.603C14.4762 162.677 14.1246 161.53 14.1867 160.367C14.1898 160.32 14.211 160.276 14.2459 160.244C14.2808 160.212 14.3267 160.195 14.3739 160.196C14.4211 160.197 14.466 160.217 14.4991 160.251C14.5322 160.285 14.5509 160.33 14.5514 160.377C14.4916 161.46 14.8192 162.528 15.4752 163.39C16.1312 164.251 17.0723 164.849 18.1286 165.075C18.3581 165.124 18.2502 165.474 18.022 165.425Z"
                fill="white"
            />
            <path
                d="M19.7759 175.319C21.784 174.053 23.2433 172.075 23.8626 169.777C23.9238 169.55 24.2726 169.657 24.2115 169.884C23.5625 172.274 22.0398 174.33 19.9472 175.642C19.7476 175.767 19.5775 175.443 19.7759 175.319Z"
                fill="white"
            />
            <path
                d="M21.3593 155.301C21.7776 155.489 22.236 155.57 22.6931 155.537C23.1503 155.503 23.5921 155.356 23.9787 155.109C24.1765 154.982 24.3465 155.306 24.1499 155.432C23.7215 155.703 23.2335 155.865 22.7284 155.903C22.2234 155.942 21.7167 155.855 21.2527 155.651C21.2073 155.636 21.1696 155.604 21.1471 155.561C21.1246 155.519 21.119 155.469 21.1316 155.423C21.1459 155.377 21.1779 155.338 21.2205 155.315C21.2632 155.292 21.313 155.287 21.3593 155.301Z"
                fill="white"
            />
            <path
                d="M49.7767 163.086C49.6554 163.169 49.5341 163.252 49.4127 163.339C47.7877 164.464 46.2565 165.72 44.8342 167.095C44.7226 167.199 44.611 167.307 44.5026 167.414C41.1111 170.762 38.3682 174.713 36.4135 179.066C35.6372 180.799 34.9934 182.589 34.4876 184.419C33.7893 186.948 33.2326 189.746 31.7854 191.833C31.6369 192.053 31.475 192.263 31.3005 192.463L17.811 192.764C17.78 192.75 17.7489 192.738 17.7177 192.723L17.1797 192.76C17.1992 192.664 17.2215 192.564 17.241 192.468C17.2521 192.412 17.2662 192.356 17.2773 192.3C17.2856 192.263 17.2941 192.226 17.2995 192.191C17.3023 192.179 17.3051 192.167 17.3079 192.157C17.3134 192.123 17.322 192.092 17.3275 192.061C17.4506 191.505 17.5778 190.949 17.7092 190.393C17.7091 190.39 17.7091 190.39 17.712 190.386C18.7236 186.151 20.0962 181.963 22.0585 178.117C22.1176 178.001 22.1764 177.882 22.2417 177.766C23.1358 176.038 24.1691 174.385 25.3314 172.826C25.9705 171.974 26.6522 171.155 27.3739 170.372C29.2424 168.353 31.4156 166.642 33.8138 165.301C38.5951 162.629 44.1746 161.521 49.38 162.972C49.5132 163.009 49.6435 163.046 49.7767 163.086Z"
                fill="#F0F0F0"
            />
            <path
                d="M49.7497 163.259C43.0622 164.748 37.0122 168.315 32.459 173.453C31.4593 174.541 30.6367 175.781 30.0216 177.127C29.4646 178.457 29.2727 179.913 29.4657 181.343C29.619 182.68 29.93 184.03 29.7568 185.381C29.658 186.071 29.4149 186.733 29.0435 187.322C28.6721 187.911 28.1809 188.415 27.6019 188.8C26.1841 189.798 24.4938 190.225 22.8263 190.585C20.9748 190.985 19.0454 191.37 17.5195 192.571C17.3346 192.717 17.1205 192.42 17.3051 192.275C19.9601 190.186 23.5877 190.531 26.5095 189.033C27.8729 188.334 29.0347 187.218 29.3418 185.662C29.6103 184.302 29.2908 182.909 29.1239 181.557C28.9152 180.165 29.0493 178.743 29.5145 177.414C30.0594 176.042 30.833 174.772 31.8022 173.66C33.9444 171.121 36.4711 168.936 39.2895 167.185C42.4951 165.168 46.0206 163.717 49.7137 162.894C49.9423 162.844 49.9769 163.208 49.7497 163.259Z"
                fill="white"
            />
            <path
                d="M33.8262 171.712C33.0712 170.828 32.6538 169.704 32.6485 168.539C32.6432 167.375 33.0503 166.247 33.7972 165.356C33.949 165.176 34.2343 165.405 34.0823 165.585C33.3853 166.413 33.0062 167.464 33.0133 168.548C33.0205 169.632 33.4135 170.678 34.1214 171.497C34.275 171.675 33.979 171.889 33.8262 171.712Z"
                fill="white"
            />
            <path
                d="M29.3002 180.676C31.6625 180.879 34.0144 180.181 35.8868 178.721C36.0722 178.576 36.2863 178.873 36.1011 179.017C34.1498 180.533 31.7008 181.254 29.243 181.037C29.0088 181.016 29.0672 180.655 29.3002 180.676Z"
                fill="white"
            />
            <path
                d="M42.5698 165.648C42.791 166.051 43.1084 166.393 43.4935 166.643C43.8787 166.892 44.3196 167.042 44.7766 167.078C45.011 167.096 44.9523 167.457 44.7194 167.44C44.2147 167.397 43.728 167.231 43.3019 166.957C42.8757 166.682 42.523 166.307 42.2747 165.864C42.2478 165.824 42.237 165.775 42.2445 165.728C42.252 165.68 42.2773 165.637 42.3151 165.608C42.3543 165.58 42.4031 165.568 42.4508 165.576C42.4985 165.583 42.5413 165.609 42.5698 165.648Z"
                fill="white"
            />
            <path
                d="M116.03 46.7166L43.4139 39.5466C42.5726 39.4589 41.7597 39.1925 41.029 38.765C40.2751 38.3247 39.6069 37.7509 39.0566 37.0716C37.8737 35.6194 37.2247 33.8028 37.2188 31.9269C37.218 31.47 37.2585 31.014 37.3397 30.5644C37.4175 30.1338 37.5364 29.7117 37.6947 29.3039C37.8479 28.9093 38.041 28.5316 38.271 28.1766C38.4972 27.8277 38.7603 27.5045 39.0557 27.2124C39.343 26.9279 39.6615 26.6769 40.0051 26.4642C40.6739 26.0503 41.4299 25.7994 42.2126 25.7315C42.6127 25.6971 43.0154 25.7075 43.4132 25.7624L116.03 35.6229C116.584 35.7055 117.109 35.9262 117.557 36.2647C118.047 36.6339 118.466 37.0896 118.793 37.6096C119.158 38.1823 119.437 38.8057 119.622 39.4597C119.823 40.1642 119.925 40.8935 119.925 41.6263C119.925 41.9834 119.901 42.3401 119.851 42.6937C119.804 43.0306 119.731 43.3635 119.634 43.6894C119.541 43.9996 119.423 44.3015 119.279 44.5917C119.143 44.869 118.98 45.1325 118.794 45.3788C118.62 45.6097 118.421 45.8208 118.201 46.0083C117.997 46.182 117.773 46.3294 117.532 46.4471C117.066 46.6755 116.546 46.7687 116.03 46.7163L116.03 46.7166Z"
                fill="#E4E4E4"
            />
            <path
                d="M83.7543 43.5299L43.4139 39.5466C42.5726 39.4589 41.7597 39.1925 41.029 38.765C40.2751 38.3247 39.6069 37.7509 39.0566 37.0716C37.8737 35.6194 37.2247 33.8028 37.2188 31.9269C37.218 31.47 37.2585 31.014 37.3397 30.5644C37.4175 30.1338 37.5364 29.7117 37.6947 29.3039C37.8479 28.9093 38.041 28.5316 38.271 28.1766C38.4972 27.8277 38.7603 27.5045 39.0557 27.2124C39.343 26.9279 39.6615 26.6769 40.0051 26.4642C40.6739 26.0503 41.4299 25.7994 42.2126 25.7315C42.6127 25.6971 43.0154 25.7075 43.4132 25.7624L83.7534 31.2412L83.7543 43.5299Z"
                fill="#137157"
            />
            <path
                d="M83.6506 44.4349C83.4641 44.4154 83.2847 44.3519 83.1273 44.2495C82.9612 44.142 82.8166 44.0044 82.7006 43.8438C82.5745 43.6702 82.4768 43.4775 82.4112 43.273C82.3407 43.0552 82.3047 42.8277 82.3047 42.5987V31.8303C82.3033 31.6096 82.3393 31.3901 82.4109 31.1814C82.4742 30.9968 82.5726 30.8262 82.7006 30.6792C82.8172 30.544 82.9637 30.4381 83.1285 30.3702C83.2946 30.3041 83.475 30.2828 83.6519 30.3084C83.8387 30.3361 84.0167 30.4065 84.1721 30.5142C84.3372 30.6288 84.4804 30.7722 84.5951 30.9376C84.7194 31.1143 84.8157 31.3092 84.8808 31.5154C84.95 31.7329 84.9853 31.9599 84.9855 32.1882V42.8697C84.9867 43.0904 84.9513 43.3097 84.8808 43.5187C84.8182 43.7053 84.7213 43.8784 84.5951 44.0292C84.4797 44.1675 84.3347 44.2779 84.1709 44.3521C84.0075 44.4243 83.8283 44.4528 83.6506 44.4349Z"
                fill="#3F3D56"
            />
            <path
                d="M19.1195 35.5959C19.0477 35.5885 18.9764 35.5762 18.9062 35.5591C18.8343 35.5416 18.7636 35.5193 18.6947 35.4924C18.6238 35.4648 18.5547 35.4327 18.4878 35.3963C18.4186 35.3586 18.3518 35.3167 18.2877 35.2708C17.9002 34.9937 17.6244 34.5864 17.5101 34.1228C17.4722 33.9686 17.4531 33.8103 17.4531 33.6515V25.5655C17.4526 25.4114 17.4717 25.2578 17.5101 25.1085C17.5462 24.9676 17.6016 24.8323 17.6748 24.7067C17.8184 24.4614 18.032 24.2649 18.288 24.1428C18.4167 24.081 18.5537 24.0386 18.6947 24.017C18.8354 23.9957 18.9784 23.9944 19.1195 24.0133C19.2653 24.0329 19.4078 24.0718 19.5434 24.1289C19.687 24.1893 19.8226 24.2671 19.9473 24.3606L26.3807 29.1583C26.4957 29.2442 26.601 29.3424 26.6947 29.4512C26.8804 29.6667 27.018 29.9196 27.0983 30.1929C27.1385 30.3299 27.1638 30.471 27.1737 30.6135C27.1737 30.6248 27.1744 30.6362 27.1758 30.6475C27.1773 30.6588 27.1773 30.6702 27.1758 30.6815V30.7498C27.1763 30.9003 27.1581 31.0503 27.1217 31.1963C27.0872 31.3339 27.0344 31.4662 26.9647 31.5897C26.8969 31.7094 26.8125 31.8186 26.7138 31.9142C26.6151 32.0101 26.5028 32.0909 26.3807 32.154L19.9467 35.4423C19.8826 35.475 19.8163 35.503 19.7481 35.5261C19.681 35.5489 19.6122 35.5667 19.5425 35.5795C19.4729 35.5923 19.4026 35.6 19.3319 35.6027C19.261 35.6054 19.1901 35.6031 19.1195 35.5959Z"
                fill="#CACACA"
            />
            <path
                d="M116.045 69.9255L43.4367 71.3001C42.6144 71.3154 41.7993 71.1441 41.0521 70.7992C40.303 70.451 39.632 69.9538 39.0797 69.3378C38.4996 68.6922 38.0444 67.9439 37.7372 67.131C37.0772 65.3772 37.0772 63.4419 37.7372 61.6881C38.0444 60.8751 38.4997 60.1266 39.0797 59.4807C39.6319 58.8642 40.3029 58.3664 41.0521 58.0175C41.7992 57.6721 42.6143 57.5003 43.4367 57.515L116.045 58.8324C116.586 58.8462 117.114 59.0064 117.572 59.296C118.061 59.6061 118.482 60.0134 118.808 60.4928C119.176 61.0287 119.456 61.6204 119.637 62.2453C120.04 63.6371 120.04 65.1156 119.637 66.5074C119.456 67.1326 119.176 67.7245 118.808 68.2608C118.482 68.7414 118.061 69.15 117.572 69.4614C117.114 69.7511 116.586 69.9115 116.045 69.9255Z"
                fill="#E4E4E4"
            />
            <path
                d="M71.8161 70.7631L43.4367 71.3002C42.6144 71.3154 41.7993 71.1442 41.0521 70.7992C40.3029 70.451 39.632 69.9539 39.0797 69.3378C38.4996 68.6923 38.0443 67.944 37.7372 67.1311C37.4079 66.262 37.2401 65.3396 37.2422 64.4098C37.2419 63.9482 37.2824 63.4875 37.3632 63.0331C37.4421 62.5892 37.5609 62.1535 37.7181 61.7311C37.8728 61.3157 38.0657 60.9156 38.2944 60.5361C38.5214 60.1595 38.7841 59.8056 39.0788 59.4796C39.3656 59.1612 39.6837 58.8726 40.0282 58.6183C40.3594 58.3742 40.7154 58.166 41.0903 57.9972C41.8274 57.6648 42.6287 57.5001 43.4367 57.5151L71.8161 58.0299V70.7631Z"
                fill="#137157"
            />
            <path
                d="M72.1442 71.5687C71.9503 71.5727 71.7585 71.5292 71.5851 71.442C71.4086 71.3522 71.2526 71.2266 71.127 71.073C70.9914 70.9077 70.8864 70.7193 70.817 70.5168C70.741 70.2968 70.7025 70.0655 70.7031 69.8326V58.6891C70.7024 58.456 70.741 58.2245 70.817 58.0043C70.8864 57.8018 70.9914 57.6133 71.127 57.4481C71.2527 57.2949 71.4087 57.1697 71.5851 57.0803C71.7585 56.9931 71.9503 56.9497 72.1442 56.9536C72.3392 56.9584 72.5302 57.0099 72.7014 57.1038C72.8768 57.2006 73.0312 57.3316 73.1554 57.4892C73.2889 57.6577 73.3923 57.8482 73.4611 58.0522C73.5358 58.2722 73.5737 58.5031 73.5732 58.7355V69.7862C73.5737 70.0186 73.5358 70.2495 73.4611 70.4695C73.3924 70.6736 73.2889 70.8642 73.1554 71.0329C73.0313 71.1907 72.8769 71.3219 72.7014 71.4188C72.5302 71.5127 72.3391 71.5641 72.1442 71.5687Z"
                fill="#3F3D56"
            />
            <path
                d="M19.1426 70.208C18.9992 70.2101 18.8562 70.1921 18.7178 70.1546C18.6473 70.1355 18.5782 70.1115 18.5109 70.0829C18.4421 70.0535 18.3753 70.0194 18.3111 69.9809C18.1825 69.9043 18.0647 69.8107 17.9608 69.7028C17.858 69.5953 17.7697 69.4748 17.6982 69.3443C17.6257 69.2118 17.5703 69.0706 17.5335 68.924C17.4954 68.7725 17.4763 68.6168 17.4766 68.4605V60.3745C17.4763 60.2182 17.4954 60.0625 17.5335 59.9109C17.5704 59.7646 17.6257 59.6236 17.6982 59.4913C17.842 59.2278 18.0538 59.008 18.3114 58.855C18.4384 58.7782 18.5752 58.7192 18.7181 58.6798C18.9965 58.6041 19.2904 58.6083 19.5665 58.6918C19.7087 58.7349 19.8444 58.797 19.9701 58.8763L26.4041 62.9165L26.4272 62.9314L26.4503 62.9465L26.4731 62.962L26.4955 62.9777C26.5988 63.0527 26.6931 63.1394 26.7766 63.2361C26.8599 63.3324 26.9322 63.4378 26.9921 63.5504C27.0525 63.6644 27.1003 63.7847 27.1346 63.9091C27.17 64.0371 27.1917 64.1684 27.1993 64.301V64.4138C27.1995 64.5663 27.1813 64.7183 27.1451 64.8665C27.11 65.0097 27.0572 65.148 26.9881 65.2781C26.92 65.4063 26.8357 65.5252 26.7375 65.632C26.6382 65.7391 26.5253 65.8326 26.4016 65.9101L19.9701 69.9574C19.9065 69.9975 19.8403 70.0334 19.7719 70.0646C19.7051 70.0951 19.6364 70.1211 19.5662 70.1422C19.4972 70.163 19.4269 70.179 19.3556 70.1901C19.2851 70.201 19.214 70.207 19.1426 70.208Z"
                fill="#CACACA"
            />
            <path
                d="M116.03 93.0166L43.414 102.908C42.6252 103.022 41.8207 102.95 41.0643 102.698C40.308 102.446 39.6205 102.02 39.0564 101.455C38.4727 100.872 38.0155 100.175 37.7136 99.406C37.3812 98.5584 37.2133 97.6547 37.2189 96.7438C37.2249 94.8677 37.8738 93.0509 39.0567 91.5985C39.607 90.9189 40.2752 90.3448 41.0291 89.9041C41.7598 89.4764 42.5727 89.2096 43.414 89.1216L116.03 81.9232C116.556 81.8702 117.085 81.9684 117.557 82.2062C118.044 82.4578 118.467 82.8186 118.793 83.2606C119.164 83.7594 119.445 84.3197 119.622 84.9161C119.827 85.5955 119.929 86.3018 119.925 87.0116C119.925 87.7444 119.824 88.4737 119.622 89.1782C119.438 89.8323 119.158 90.4558 118.793 91.0286C118.466 91.5488 118.047 92.0047 117.557 92.3741C117.109 92.7129 116.585 92.9338 116.03 93.0166Z"
                fill="#E4E4E4"
            />
            <path
                d="M104.612 94.5717L43.414 102.907C42.6252 103.022 41.8207 102.95 41.0643 102.698C40.308 102.446 39.6205 102.02 39.0564 101.455C38.4727 100.872 38.0155 100.175 37.7136 99.4058C37.3813 98.5586 37.2134 97.6554 37.2189 96.7448C37.219 96.2786 37.2595 95.8133 37.3399 95.3542C37.5802 93.9745 38.1713 92.6805 39.0558 91.5974C39.3427 91.2458 39.6604 90.9207 40.0052 90.6261C40.3347 90.3438 40.6902 90.0937 41.067 89.8792C41.4308 89.6723 41.8146 89.5029 42.2125 89.3736C42.6026 89.247 43.0051 89.1626 43.4131 89.1217L104.612 83.0549V94.5717Z"
                fill="#137157"
            />
            <path
                d="M19.1192 104.665C19.0485 104.674 18.9772 104.678 18.9059 104.678C18.8352 104.677 18.7646 104.672 18.6947 104.661C18.5537 104.64 18.4167 104.597 18.288 104.535C18.0318 104.413 17.8182 104.216 17.6748 103.97C17.6016 103.845 17.5461 103.709 17.5101 103.569C17.4719 103.42 17.4527 103.267 17.4531 103.113V95.0261C17.4531 94.8676 17.4723 94.7097 17.5101 94.5558C17.6246 94.0921 17.9005 93.6847 18.288 93.4074C18.4138 93.3168 18.5505 93.2424 18.6947 93.1859C18.8311 93.1325 18.9739 93.0977 19.1195 93.0823C19.2609 93.0676 19.4036 93.073 19.5434 93.0984C19.6837 93.1241 19.8196 93.1703 19.9467 93.2353L26.3807 96.5212L26.4041 96.5332L26.4272 96.5459L26.4499 96.5589L26.4724 96.5722C26.57 96.6315 26.6599 96.7027 26.7402 96.7842C26.8202 96.8653 26.8903 96.9558 26.949 97.0536C27.0089 97.1538 27.0573 97.2605 27.0933 97.3716C27.1309 97.4878 27.1561 97.6076 27.1685 97.7292C27.1701 97.7452 27.1715 97.7613 27.1728 97.7774C27.174 97.7934 27.175 97.8097 27.1758 97.8262C27.1758 97.8425 27.1765 97.8588 27.1777 97.8753C27.1789 97.8919 27.1789 97.9085 27.1777 97.9251C27.1777 98.0798 27.1595 98.2339 27.1235 98.3843C27.0529 98.6832 26.9146 98.9617 26.7193 99.1983C26.6193 99.3175 26.5057 99.4245 26.3807 99.5169L19.9467 104.317C19.8832 104.364 19.8169 104.408 19.7481 104.447C19.6818 104.486 19.6131 104.519 19.5425 104.549C19.4739 104.578 19.4036 104.602 19.3319 104.622C19.262 104.641 19.191 104.655 19.1192 104.665Z"
                fill="#CACACA"
            />
            <path
                d="M104.173 86.2989C104.621 86.2341 105.077 86.2687 105.511 86.4002C105.944 86.5317 106.343 86.757 106.68 87.0601C107.017 87.3633 107.284 87.7369 107.462 88.1548C107.639 88.5726 107.723 89.0245 107.708 89.4785L117.975 93.4454L113.323 96.7814L104.287 92.5029C103.529 92.4269 102.825 92.0743 102.309 91.5119C101.792 90.9496 101.499 90.2166 101.485 89.4519C101.471 88.6871 101.737 87.9438 102.232 87.3627C102.727 86.7815 103.418 86.403 104.173 86.2989Z"
                fill="#FFB6B6"
            />
            <path
                d="M143.153 84.3516L144.89 87.5215C144.47 89.532 143.386 91.341 141.812 92.6542C139.153 94.9055 125.249 103.29 125.249 103.29L107.695 94.4443L110.766 90.3482L124.547 94.9843L132.321 87.6149L143.153 84.3516Z"
                fill="#137157"
            />
            <path d="M144.745 134.058L142.93 134.402L153.874 192.709L155.689 192.365L144.745 134.058Z" fill="#CACACA" />
            <path
                d="M126.677 192.365L128.492 192.708L139.437 134.401L137.622 134.058L126.677 192.365Z"
                fill="#CACACA"
            />
            <path
                d="M139.926 136.805C147.492 136.805 153.625 134.799 153.625 132.324C153.625 129.85 147.492 127.844 139.926 127.844C132.36 127.844 126.227 129.85 126.227 132.324C126.227 134.799 132.36 136.805 139.926 136.805Z"
                fill="#CACACA"
            />
            <path
                d="M110.827 189.036L107.053 189.036L105.258 174.423L110.828 174.423L110.827 189.036Z"
                fill="#FFB6B6"
            />
            <path
                d="M111.787 192.709L99.6172 192.708V192.554C99.6173 191.293 100.116 190.083 101.005 189.192C101.893 188.3 103.098 187.799 104.354 187.799L111.787 187.799L111.787 192.709Z"
                fill="#2F2E41"
            />
            <path
                d="M129.149 169.153L125.852 170.997L117.195 159.111L122.061 156.388L129.149 169.153Z"
                fill="#FFB6B6"
            />
            <path
                d="M131.773 171.89L121.143 177.838L121.068 177.703C120.456 176.601 120.306 175.301 120.649 174.088C120.992 172.875 121.802 171.849 122.899 171.235L129.391 167.602L131.773 171.89Z"
                fill="#2F2E41"
            />
            <path
                d="M150.584 121.369C150.584 121.369 152.414 127.061 141.186 134.258L126.726 148.722L111.701 184.801L102.781 180.787L116.499 144.031L124.52 129.931L124.753 122.281L126.726 116.888L141.041 111.789L150.584 121.369Z"
                fill="#2F2E41"
            />
            <path
                d="M121.226 168.916L106.302 146.329C105.617 145.29 105.313 144.043 105.444 142.804C105.574 141.564 106.131 140.409 107.018 139.537L122.129 124.698L124.691 122.126H130.424L127.736 132.084L118.409 142.697L129.191 161.849L121.226 168.916Z"
                fill="#2F2E41"
            />
            <path
                d="M132.194 90.4222L136.691 83.9617L146.966 83.1318C146.966 83.1318 155.8 91.4866 154.193 102.488C152.586 113.489 151.046 124.305 151.046 124.305C151.046 124.305 135.038 112.871 126.727 116.888L132.194 90.4222Z"
                fill="#137157"
            />
            <path
                d="M129.138 124.127C129.218 123.68 129.394 123.255 129.655 122.884C129.916 122.513 130.254 122.203 130.647 121.977C131.04 121.752 131.477 121.615 131.928 121.577C132.379 121.54 132.833 121.602 133.257 121.759L140.239 113.225L141.93 118.707L135.041 125.97C134.73 126.669 134.175 127.229 133.481 127.544C132.787 127.859 132.001 127.907 131.274 127.68C130.546 127.453 129.927 126.966 129.533 126.311C129.14 125.656 128.999 124.879 129.138 124.127Z"
                fill="#FFB6B6"
            />
            <path
                d="M137.927 123.572L134.852 119.114L143.583 107.427L139.085 97.7507L139.413 86.2413L143.13 85.552L143.176 85.5752C144.983 86.6194 146.38 88.2528 147.135 90.204C148.062 92.5311 150.132 101.267 151.283 106.267C151.51 107.236 151.485 108.247 151.212 109.203C150.938 110.16 150.425 111.03 149.722 111.732L137.927 123.572Z"
                fill="#137157"
            />
            <path
                d="M145.767 78.3073C147.768 74.9281 146.661 70.5603 143.295 68.5515C139.929 66.5428 135.577 67.6538 133.576 71.033C131.575 74.4122 132.682 78.78 136.048 80.7888C139.415 82.7975 143.766 81.6865 145.767 78.3073Z"
                fill="#FFB6B6"
            />
            <path
                d="M144.203 72.1145C144.128 73.1481 145.093 76.9593 145.093 76.9593C145.801 75.7641 144.689 79.736 144.689 79.736C156.305 71.6085 147.127 63.4431 143.537 64.1689C143.612 63.1353 140.684 63.5448 140.684 63.5448C139.568 61.8007 137.123 64.1158 137.123 64.1158L137.595 63.319C135.697 63.8037 133.82 69.6932 133.82 69.6932C134.464 72.234 144.278 71.0809 144.203 72.1145Z"
                fill="#2F2E41"
            />
            <path
                d="M104.339 95.345C104.183 95.3674 104.024 95.346 103.88 95.2832C103.734 95.2173 103.605 95.1176 103.504 94.9921C103.39 94.8515 103.304 94.6909 103.249 94.5184C103.186 94.3213 103.155 94.1155 103.156 93.9086V83.8149C103.156 83.6009 103.187 83.3881 103.249 83.1833C103.306 82.9933 103.392 82.8134 103.504 82.6499C103.605 82.5009 103.732 82.3723 103.88 82.2707C104.017 82.1769 104.175 82.1186 104.339 82.101C104.496 82.0856 104.654 82.1137 104.797 82.1823C104.942 82.2543 105.07 82.3587 105.17 82.4876C105.281 82.6314 105.367 82.7942 105.421 82.9682C105.483 83.1649 105.514 83.3702 105.513 83.5767V93.5941C105.513 93.8075 105.482 94.0198 105.421 94.2242C105.364 94.4156 105.28 94.5974 105.17 94.7637C105.07 94.916 104.943 95.049 104.797 95.1562C104.661 95.2553 104.504 95.32 104.339 95.345Z"
                fill="#3F3D56"
            />
            <path
                d="M199.632 193H0.365483C0.268452 193 0.175489 192.961 0.106978 192.892C0.0384659 192.823 0 192.73 0 192.632C0 192.535 0.0384659 192.441 0.106978 192.372C0.175489 192.303 0.268452 192.264 0.365483 192.264H199.632C199.73 192.264 199.823 192.303 199.892 192.372C199.96 192.441 199.999 192.535 199.999 192.632C199.999 192.73 199.96 192.823 199.892 192.892C199.823 192.961 199.73 193 199.632 193Z"
                fill="#CACACA"
            />
        </g>
        <defs>
            <clipPath id="clip0_9450_187503">
                <rect width="200" height="193" fill="white" />
            </clipPath>
        </defs>
    </svg>
)
