import { SVGProps } from 'react'
export const Flags = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="109" height="34" viewBox="0 0 109 34" fill="none" {...props}>
        <path d="M50.2005 0.5H0V16.5214H50.2005V0.5Z" fill="black" />
        <path d="M50.2005 16.5212H0V33.6108H50.2005V16.5212Z" fill="#FF0000" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.1789 17.0555C34.2213 22.0237 30.4302 26.0887 25.7125 26.1336C20.9948 26.1784 17.1325 22.1866 17.0902 17.2176C17.0902 17.1631 17.0902 17.1093 17.0902 17.0555C17.0479 12.0872 20.8389 8.02154 25.5566 7.97669C30.2743 7.93184 34.1359 11.9244 34.1783 16.8933C34.1796 16.9471 34.1796 17.001 34.1789 17.0555Z"
            fill="#FFFF00"
        />
        <path d="M108.712 0.5H64.001V33.5609H108.712V0.5Z" fill="#1E8514" />
        <path d="M108.711 7.58496H64V26.4767H108.711V7.58496Z" fill="#000100" />
        <path d="M108.711 8.76611H64V25.2966H108.711V8.76611Z" fill="#0043FB" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.5312 23.6436L81.8842 21.0444C81.8842 21.0444 80.2372 18.6824 80.2372 15.6126C80.2372 12.5429 82.5902 10.1809 85.414 10.1809L86.5909 12.0697L87.767 10.1809C90.1207 10.1809 92.9439 12.0697 92.9439 15.3761C92.9439 18.6824 91.2962 21.0411 91.2962 21.0411L93.8862 23.8749L88.9445 22.6939V15.1421C88.9445 14.6696 88.0028 13.7252 86.5915 13.7252C85.4147 13.7252 84.4736 14.6696 84.4736 15.1421V22.4626L79.5312 23.6436Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.634 18.1524L85.9721 17.068L85.0762 16.3729L86.2085 16.3602L86.5904 15.2905L86.9523 16.3669L88.0813 16.4009L87.1728 17.0787L87.4909 18.1697L86.5698 17.5119L85.634 18.1524Z"
            fill="white"
        />
    </svg>
)
