import { toast } from 'sonner'
import { API_ROUTES } from '@/common/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    type ListOrdersParams,
    cancelOrder,
    getCompanyBySecurityCode,
    getHistoricalPrice,
    getHoldingsHistory,
    getInstrument,
    getInstrumentPrices,
    getRecommendedSecurities,
    getSecuritiesBuyOrderStats,
    getSecuritiesByTag,
    getSecuritiesByTags,
    getTradingAccount,
    getTrendingSecurities,
    listHoldings,
    listOrders,
    listUserPledges,
    placeBuyOrder,
    placeSellOrder,
} from './tradingApi'
import { HistoricalPricesRangePreset, Maybe, OrderPayload } from '@/common/types'
import { type QueryOptions, defaultOptions } from '@/common/utils/queryOptions'

type ListOrdersQueryOptions = QueryOptions & ListOrdersParams

export const useFetchEquityDetails = (securityCode: Maybe<string>) => {
    return useQuery({
        queryKey: [API_ROUTES.instrumentProfile, securityCode],
        queryFn: () => getInstrument(securityCode || ''),
        enabled: !!securityCode,
    })
}

export const useFetchEquityPrices = (securityCode: Maybe<string>) => {
    return useQuery({
        queryKey: [API_ROUTES.instrumentPrices, securityCode],
        queryFn: () => getInstrumentPrices(securityCode || ''),
        enabled: !!securityCode,
    })
}

export const useFetchHistoricalPrice = (securityCode: Maybe<string>, rangePreset: HistoricalPricesRangePreset) => {
    return useQuery({
        queryKey: [API_ROUTES.historicalPrices, securityCode, rangePreset],
        queryFn: () => getHistoricalPrice(securityCode, rangePreset),
        enabled: !!securityCode,
    })
}

export const useFetchTradingAccount = () => {
    return useQuery({
        queryKey: [API_ROUTES.tradingAccount],
        queryFn: getTradingAccount,
    })
}

export const useListHoldings = (options = defaultOptions) => {
    const { enabled, refetchInterval } = options
    return useQuery({
        queryKey: [API_ROUTES.listHoldings],
        queryFn: listHoldings,
        enabled,
        refetchInterval,
    })
}

export const useListUserPledges = () => {
    return useQuery({
        queryKey: [API_ROUTES.campaignUserPledgeList],
        queryFn: listUserPledges,
    })
}

export const useListOrders = (options: ListOrdersQueryOptions = defaultOptions) => {
    const { enabled, refetchInterval, ...params } = options
    return useQuery({
        queryKey: [API_ROUTES.listOrders],
        queryFn: () => listOrders(params),
        enabled,
        refetchInterval,
    })
}

export const usePlaceBuyOrder = () => {
    return useMutation({
        mutationFn: (order: OrderPayload) => placeBuyOrder(order),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to place the order', err)
            toast.error('Failed to place the order. Please try again')
        },
    })
}

export const usePlaceSellOrder = () => {
    return useMutation({
        mutationFn: (order: OrderPayload) => placeSellOrder(order),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to place the order', err)
            toast.error('Failed to place the order. Please try again')
        },
    })
}

export const useCancelOrder = () => {
    return useMutation({
        mutationFn: (orderId: string) => cancelOrder(orderId),
        onSuccess: () => {},
        onError: (err: any) => {
            console.log('Failed to cancel the order', err)
            toast.error('Failed to cancel the order. Please try again')
        },
    })
}

export const useFetchRecommendedSecurities = () => {
    return useQuery({
        queryKey: [API_ROUTES.recommendedSecurities],
        queryFn: getRecommendedSecurities,
        enabled: true,
    })
}

export const useFetchHoldingsHistory = (range: string) => {
    return useQuery({
        queryKey: [API_ROUTES.getHoldingsHistory, range],
        queryFn: () => getHoldingsHistory(range),
        enabled: true,
    })
}

export const useFetchTrendingSecurities = () => {
    return useQuery({
        queryKey: [API_ROUTES.trendingSecurities],
        queryFn: () => getTrendingSecurities(),
        enabled: true,
    })
}

export const useFetchSecuritiesByTag = (tag: string) => {
    return useQuery({
        queryKey: [API_ROUTES.securitiesByTag, tag],
        queryFn: () => getSecuritiesByTag(tag),
        enabled: true,
    })
}

export const useFetchSecuritiesByTags = (tags: string[]) => {
    return useQuery({
        queryKey: [API_ROUTES.securitiesByTags, tags],
        queryFn: () => getSecuritiesByTags(tags),
        enabled: !!tags.length,
    })
}

export const useFetchTopTradedSecurities = () => {
    const { data } = useQuery({
        queryKey: [API_ROUTES.securitiesByOrderStats],
        queryFn: () => getSecuritiesBuyOrderStats(),
        enabled: true,
    })

    const topTradedSecurities = data?.slice(0, 5) ?? []

    return { data: topTradedSecurities }
}

export const useFetchCompanyBySecurityCode = (securityCode: string) => {
    return useQuery({
        queryKey: [API_ROUTES.getCompanyByCode, securityCode],
        queryFn: () => getCompanyBySecurityCode(securityCode),
        enabled: true,
    })
}
