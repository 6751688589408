import { H3 } from '@/common/ui'
import { SecurityProfile } from './securityProfile.tsx'

export default function CompanyProfile({ data }: { data: any }) {
    const { description, history } = data || {}
    if (!data) return null

    const hasData = description || history
    if (!hasData) return <div>No company data available. Please check back later.</div>

    return (
        <>
            <H3>Company snapshot</H3>
            <p className="mb-4">{description}</p>
            <p>{history}</p>
            <SecurityProfile about={data} />
        </>
    )
}
