import { cn } from '@/common/utils/utils'
import { useListHoldings } from '@/trading/tradingQueries'
import { CheckIcon, ChevronRightIcon, CircleDashedIcon, CircleIcon, ClipboardIcon } from 'lucide-react'
import { useNavigate } from 'react-router'
import { analytics } from '@/common/analytics/analytics.ts'
import { useCampaignsWithHoldings } from './useCampaignsWithHoldings'

const CheckedIcon = () => (
    <div className="w-6 h-6 bg-[#32871d] rounded-full flex items-center justify-center">
        <CheckIcon width={16} height={16} color="white" />
    </div>
)

const CompletionRing = ({
    hasCompletedFirstStep,
    hasCompletedSecondStep,
}: {
    hasCompletedFirstStep: boolean
    hasCompletedSecondStep: boolean
}) => {
    const hasCompletedAllSteps = hasCompletedFirstStep && hasCompletedSecondStep
    const hasCompletedHalf =
        (hasCompletedFirstStep && !hasCompletedSecondStep) || (!hasCompletedFirstStep && hasCompletedSecondStep)

    const halfCircleRing = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Progress">
                <path
                    id="Ellipse 299"
                    d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z"
                    fill="#D5D8D3"
                />
                <path
                    id="Ellipse 300"
                    d="M10.9711 1.55054C10.8899 0.726096 11.494 -0.0179169 12.3222 0.00432451C13.4569 0.0348005 14.5841 0.226204 15.6697 0.574886C17.2326 1.07687 18.6758 1.89415 19.9102 2.97618C21.1445 4.05821 22.1438 5.38198 22.8461 6.86562C23.5484 8.34926 23.9389 9.96122 23.9934 11.6018C24.0479 13.2424 23.7652 14.8767 23.1628 16.4036C22.5604 17.9306 21.6512 19.3177 20.4913 20.4793C19.3314 21.6408 17.9456 22.552 16.4195 23.1565C15.3594 23.5765 14.2473 23.8422 13.1171 23.9479C12.2923 24.025 11.6401 23.3227 11.6665 22.4947C11.6928 21.6667 12.3895 21.0297 13.2104 20.9182C13.9297 20.8206 14.6363 20.6361 15.3146 20.3674C16.4592 19.914 17.4986 19.2306 18.3685 18.3594C19.2384 17.4883 19.9203 16.448 20.3721 15.3027C20.8239 14.1575 21.0359 12.9318 20.995 11.7013C20.9542 10.4709 20.6613 9.26195 20.1346 8.14922C19.6078 7.03649 18.8584 6.04366 17.9326 5.23213C17.0068 4.42061 15.9244 3.80765 14.7523 3.43116C14.0577 3.20806 13.3403 3.07081 12.6162 3.02112C11.7897 2.9644 11.0522 2.37498 10.9711 1.55054Z"
                    fill="#32871D"
                />
            </g>
        </svg>
    )

    if (hasCompletedAllSteps) {
        return (
            <div className="inline-flex gap-1 text-stone-700 font-semibold">
                100% <CircleIcon strokeWidth={3} color="#32871D" />
            </div>
        )
    }

    if (hasCompletedHalf) {
        return <div className="inline-flex gap-1 text-stone-700 font-semibold">50% {halfCircleRing}</div>
    }

    return (
        <div className="inline-flex gap-1 text-stone-700 font-semibold">
            0% <CircleIcon strokeWidth={3} color="#D5D8D3" />
        </div>
    )
}

function LineItem({
    title,
    description,
    onClick,
    isComplete,
}: {
    title: string
    description: string
    onClick: () => void
    isComplete: boolean
}) {
    return (
        <div className="flex flex-row gap-2">
            <div className="w-1/10">{isComplete ? <CheckedIcon /> : <CircleDashedIcon color="#D5D8D3" />}</div>
            <div className="justify-end items-start gap-2.5 inline-flex">
                <div className="grow shrink text-left basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                    <button
                        disabled={isComplete}
                        className="group justify-end w-full items-center inline-flex"
                        onClick={onClick}
                    >
                        <div
                            className={cn('text-left grow shrink', {
                                'line-through text-neutral-500': isComplete,
                                'group-hover:text-[#137157]': !isComplete,
                            })}
                        >
                            {title}
                        </div>
                        {isComplete ? null : (
                            <div className="w-6 h-6 relative">
                                <ChevronRightIcon className="group-hover:text-[#137157] text-stone-400" />
                            </div>
                        )}
                    </button>
                    <div
                        className={cn('text-stone-700 text-sm leading-tight', {
                            'text-neutral-500': isComplete,
                        })}
                    >
                        {description}
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ToDoListView({
    hasNoInvestments,
    hasNoCampaigns,
    isLoading,
}: {
    hasNoInvestments: boolean
    hasNoCampaigns: boolean
    isLoading: boolean
}) {
    const navigate = useNavigate()

    return (
        <div
            className={cn(
                'p-6 bg-white h-[400px] shadow-lg rounded-[10px] border border-[#d5d8d3] flex-col justify-start items-start gap-6 inline-flex',
                {
                    'hidden xl:flex': !hasNoInvestments && !hasNoCampaigns,
                },
            )}
        >
            {isLoading ? (
                <div className="w-full animate-pulse flex space-x-4">
                    <div className="rounded-full bg-stone-100 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-stone-100 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-stone-100 rounded col-span-2"></div>
                                <div className="h-2 bg-stone-100 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-stone-100 rounded"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="justify-between w-full inline-flex">
                        <div className="inline-flex gap-2">
                            <div className="w-6 h-6 relative">
                                <ClipboardIcon />
                            </div>
                            <div className="text-black text-base font-semibold  leading-snug">To do</div>
                        </div>
                        <CompletionRing
                            hasCompletedFirstStep={!hasNoInvestments}
                            hasCompletedSecondStep={!hasNoCampaigns}
                        />
                    </div>
                    <div className="h-0 w-full border border-[#ecefe9]"></div>
                    <LineItem
                        title="Make your first investment"
                        description="Invest in shares and build your ethical portfolio"
                        onClick={() => {
                            analytics.track('DashboardToDoList MakeFirstInvestment clicked')
                            navigate('/trading')
                        }}
                        isComplete={!hasNoInvestments}
                    />
                    <LineItem
                        title="Join your first campaign"
                        description="Change big organisations for the better through activist investing"
                        onClick={() => {
                            analytics.track('DashboardToDoList JoinCampaign clicked')
                            navigate('/campaigns')
                        }}
                        isComplete={!hasNoCampaigns}
                    />
                </>
            )}
        </div>
    )
}

export function ToDoList() {
    const { data: holdingsData, isLoading: isLoadingHoldings } = useListHoldings()
    const { campaignsWithHoldings, isLoading: isLoadingCampaigns } = useCampaignsWithHoldings()

    const hasNoInvestments = !holdingsData || holdingsData?.holdings.length === 0
    const hasNoCampaigns = campaignsWithHoldings.length === 0

    return (
        <ToDoListView
            isLoading={isLoadingHoldings || isLoadingCampaigns}
            hasNoInvestments={hasNoInvestments}
            hasNoCampaigns={hasNoCampaigns}
        />
    )
}
