import { PriceDiff } from '@/common/ui/PriceDiff'
import { useFetchHoldingsHistory, useListHoldings } from '@/trading/tradingQueries'
import { useFetchBalance } from '@/wallet/walletQueries'
import Money from '@/common/models/money.ts'
import { useEffect, useState } from 'react'
import { useCampaignsWithHoldings } from './useCampaignsWithHoldings'

function PortfolioSummaryView({
    totalValue,
    balance,
    totalChange,
    totalChangePercent,
    changeSinceYesterday,
    changeSinceYesterdayPercent,
}: {
    totalValue?: number
    balance?: number
    totalChange: number
    totalChangePercent: number
    changeSinceYesterday: number
    changeSinceYesterdayPercent: number
}) {
    if (totalValue === undefined || balance === undefined) {
        return null
    }

    const changeSinceYesterdayLabel = isFinite(changeSinceYesterdayPercent)
        ? `$${Math.abs(changeSinceYesterday)} (${Math.abs(changeSinceYesterdayPercent || 0)}%)`
        : `$${Math.abs(changeSinceYesterday)}`
    const totalChangeLabel = isFinite(totalChangePercent)
        ? `$${Math.abs(totalChange)} (${Math.abs(totalChangePercent || 0)}%)`
        : `$${Math.abs(totalChange)}`

    return (
        <div className="p-6 bg-white h-[400px] shadow-lg rounded-[10px] border border-[#d5d8d3] justify-start items-start gap-2.5 inline-flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="text-center text-black text-[40px] font-['Tangerine'] leading-[44px]">
                        {`${Money.of(totalValue)}`}
                    </div>
                </div>
                <div className="self-stretch h-[140px] flex-col justify-start items-start gap-9 flex">
                    <div className="self-stretch h-[52px] flex-col justify-start items-start gap-2.5 flex">
                        <div className="text-center text-black text-sm  leading-tight">Today’s change</div>
                        <div className="justify-start items-center inline-flex font-bold">
                            <PriceDiff label={changeSinceYesterdayLabel} value={changeSinceYesterday} />
                        </div>
                    </div>
                    <div className="self-stretch h-[52px] flex-col justify-start items-start gap-2.5 flex">
                        <div className="text-center text-black text-sm  leading-tight">Total change</div>
                        <div className="justify-start items-center inline-flex  font-bold">
                            <PriceDiff label={totalChangeLabel} value={totalChangePercent || 0} />
                        </div>
                    </div>
                </div>
                <div className="self-stretch h-[0px] border border-[#ecefe9]"></div>
                <div className="self-stretch h-[54px] flex-col justify-start items-start gap-2.5 flex">
                    <div className="text-center text-black text-sm  leading-tight">Available balance</div>
                    <div className="self-stretch justify-between items-center inline-flex">
                        <div className="text-center text-black text-base font-bold  leading-snug">{`${Money.of(balance)}`}</div>
                        <div className="w-6 h-6 relative"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PortfolioSummaryLoader() {
    return (
        <div className="p-6 bg-white h-[400px] shadow-lg rounded-[10px] border border-[#d5d8d3] justify-start items-start gap-2.5 inline-flex">
            <div className="w-full animate-pulse flex space-x-4">
                <div className="rounded-full bg-stone-100 h-10 w-10"></div>
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-stone-100 rounded"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-stone-100 rounded col-span-2"></div>
                            <div className="h-2 bg-stone-100 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-stone-100 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface PortfolioValueProps {
    hidePledged?: boolean
}

export function PortfolioSummary({ hidePledged }: PortfolioValueProps) {
    const { data: holdingsData, isLoading: isLoadingHoldings } = useListHoldings()
    const { data: balance, isLoading: isLoadingBalance } = useFetchBalance()
    const { data: holdingsHistory, isLoading: isLoadingHistory } = useFetchHoldingsHistory('1D')
    const { campaignsWithHoldings } = useCampaignsWithHoldings()
    const [totalChange, setTotalChange] = useState(0)
    const [totalChangePercent, setTotalChangePercent] = useState(0)
    const [totalHoldingsValue, setTotalHoldingsValue] = useState(0)
    const [changeSinceYesterday, setChangeSinceYesterday] = useState(0)
    const [changeSinceYesterdayPercent, setChangeSinceYesterdayPercent] = useState(0)

    useEffect(() => {
        const pledgedSecurityCodes =
            campaignsWithHoldings?.flatMap((cp) =>
                Object.keys(cp.pledges ?? {}).filter((key) => cp.pledges[key] > 0),
            ) ?? []
        const pledgedSecurities = hidePledged ? pledgedSecurityCodes : []
        const filteredHoldings = holdingsData?.holdings.filter(
            (entry: { securityCode: string }) => !pledgedSecurities.includes(entry.securityCode),
        )
        const filteredHistory = holdingsHistory?.filter(
            (entry: { securityCode: string }) => !pledgedSecurities.includes(entry.securityCode),
        )
        const totalHoldingValue =
            filteredHoldings?.reduce((acc, cur) => {
                return acc + cur.currentValue
            }, 0) ?? 0
        setTotalHoldingsValue(totalHoldingValue)

        const totalCost = filteredHoldings?.map((holding) => holding.cost).reduce((a, b) => (a ?? 0) + (b ?? 0), 0) ?? 0
        const totalChange = totalHoldingValue - totalCost
        setTotalChange(totalChange)
        setTotalChangePercent((totalChange / totalCost) * 100)

        const yesterdaysTotalValue = filteredHistory?.[0]?.totalValue ?? totalHoldingValue
        const changeSinceYesterday = totalHoldingValue - yesterdaysTotalValue
        setChangeSinceYesterday(changeSinceYesterday)
        setChangeSinceYesterdayPercent(yesterdaysTotalValue ? (changeSinceYesterday / yesterdaysTotalValue) * 100 : 0)
    }, [hidePledged, holdingsData?.holdings, holdingsHistory, campaignsWithHoldings])

    if (isLoadingBalance || isLoadingHistory || isLoadingHoldings) return <PortfolioSummaryLoader />

    return (
        <PortfolioSummaryView
            totalChangePercent={Math.round(totalChangePercent * 100) / 100}
            changeSinceYesterdayPercent={Math.round(changeSinceYesterdayPercent * 100) / 100}
            changeSinceYesterday={Math.round(changeSinceYesterday * 100) / 100}
            totalChange={Math.round(totalChange * 100) / 100}
            totalValue={totalHoldingsValue}
            balance={balance?.availableBalance}
        />
    )
}
