import { API_ROUTES } from '@/common/constants'
import { InstrumentSearchRecordT, InstrumentT, ListUserPledgesResponse } from '@/common/types'
import { app } from '../common/api/apiClient'
import type {
    EquityT,
    HistoricalPricesRangePreset,
    InstrumentPrices,
    ListHoldingsResponse,
    ListOrdersResponse,
    OrderPayload,
    PriceDataPoint,
    TopMarketDataResponse,
    TradingAccountDetails,
} from '@/common/types'
import { insertStringVariables } from '@/common/utils/utils'
import { Maybe } from 'yup'

export type ListOrdersParams = {
    from?: string
    to?: string
}

export async function getTopCompanies(pageNumber: number = 1): Promise<TopMarketDataResponse> {
    const res = await app.get<TopMarketDataResponse>(`${API_ROUTES.topCompanies}?page=${pageNumber}`)
    return res.data
}

export const searchInstruments = async (query: string) => {
    const res = await app.get<{ matches: InstrumentSearchRecordT[] }>(`${API_ROUTES.securitiesSearch}?q=${query}`)
    return res.data
}

export const getInstrument = async (securityCode: string): Promise<EquityT> => {
    const res = await app.get<EquityT>(`${insertStringVariables(API_ROUTES.instrumentProfile, securityCode)}`)
    return res.data
}

export const getInstrumentPrices = async (securityCode: string): Promise<InstrumentPrices> => {
    const res = await app.get<InstrumentPrices>(`${insertStringVariables(API_ROUTES.instrumentPrices, securityCode)}`)
    return res.data
}

export const getHistoricalPrice = async (securityCode: Maybe<string>, rangePreset: HistoricalPricesRangePreset) => {
    if (!securityCode) return null
    const res = await app.get<PriceDataPoint[]>(
        `${insertStringVariables(API_ROUTES.historicalPrices, securityCode)}?rangePreset=${rangePreset}`,
    )
    return res.data
}

export const getTradingAccount = async () => {
    const res = await app.get<TradingAccountDetails>(API_ROUTES.tradingAccount)
    return res.data
}

export const placeBuyOrder = async (order: OrderPayload) => {
    const res = await app.post<{ orderId: string }>(API_ROUTES.buyOrder, order)
    return res.data
}

export const placeSellOrder = async (order: OrderPayload) => {
    const res = await app.post<{ orderId: string }>(API_ROUTES.sellOrder, order)
    return res.data
}

export async function listOrders(params: ListOrdersParams = {}): Promise<ListOrdersResponse> {
    const from = params.from || new Date(new Date().getFullYear(), 0, 1).toISOString()
    const to = params.to || new Date().toISOString()
    const res = await app.get<ListOrdersResponse>(`${API_ROUTES.listOrders}?from=${from}&to=${to}`)
    return res.data
}

export async function listHoldings(): Promise<ListHoldingsResponse> {
    const res = await app.get<ListHoldingsResponse>(`${API_ROUTES.listHoldings}`)
    return res.data
}

export async function listUserPledges(): Promise<ListUserPledgesResponse> {
    const res = await app.get<ListUserPledgesResponse>(`${API_ROUTES.campaignUserPledgeList}`)
    return res.data
}

export const getCompanyBySecurityCode = async (securityCode: string): Promise<InstrumentT> => {
    const res = await app.get<InstrumentT>(`${insertStringVariables(API_ROUTES.getCompanyByCode, securityCode)}`)
    return res.data
}

export const cancelOrder = async (orderId: string) => {
    const res = await app.put(`${insertStringVariables(API_ROUTES.cancelOrder, orderId)}`, {})
    return res.data
}

export const getRecommendedSecurities = async () => {
    const res = await app.get<{ securities: InstrumentT[] }>(API_ROUTES.recommendedSecurities)
    return res.data
}

export const getHoldingsHistory = async (range: string) => {
    const res = await app.get(`${API_ROUTES.getHoldingsHistory}?rangePreset=${range}`)
    return res.data
}

export const getTrendingSecurities = async () => {
    const res = await app.get(API_ROUTES.trendingSecurities)
    return res.data
}

export const getSecuritiesByTag = async (tag: string) => {
    const res = await app.get(`${API_ROUTES.securitiesByTag}?tag=${tag}`)
    return res.data
}

export const getSecuritiesByTags = async (tags: string[]) => {
    const res = await app.get(`${API_ROUTES.securitiesByTags}?tags=${encodeURI(tags.join(','))}`)
    return res.data
}

export const getSecuritiesBuyOrderStats = async () => {
    const res = await app.get(`${API_ROUTES.securitiesByOrderStats}`)
    return res.data
}
