import { Helmet } from 'react-helmet'

import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { useFetchSecuritiesByTags, useFetchTrendingSecurities } from './tradingQueries'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { H3 } from '@/common/ui'
import { useEffect, useRef, useState } from 'react'
import { analytics } from '@/common/analytics/analytics'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from '@/common/constants.ts'
import { MultiSelect } from '@/common/ui/MultiSelect'
import { SecuritySnapshot } from '@/common/ui/SecuritySnapshot'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { TradingBlankState } from '@/common/assets/svg/tradingBlankState'
import { InfoIconTooltip } from '@/common/ui/tooltip'

type Filter = {
    name: string
    emoji: string
    tag: string
    isPositive: boolean
}

const FILTERS: Filter[] = [
    { name: 'Renewable energy', emoji: '🔋', tag: '🔋 Renewable Energy', isPositive: true },
    { name: 'Energy transition materials', emoji: '⛏️⚡️', tag: '⛏️⚡️ Energy transition materials', isPositive: true },
    { name: 'Water efficiency', emoji: '💧', tag: '💧 Water Efficiency', isPositive: true },
    { name: 'Nutritious foods', emoji: '🥕', tag: '🥬 Nutritious foods', isPositive: true },
    { name: 'Recycling and waste management', emoji: '♻️', tag: '♻️ Recycling and waste management', isPositive: true },
    { name: 'Healthcare', emoji: '🏥', tag: '🏥 Healthcare ', isPositive: true },
    { name: 'Electric vehicles', emoji: '🚗⚡️', tag: '🚗⚡ Electric vehicles', isPositive: true },
    { name: 'Education', emoji: '🎓', tag: '🎓 Education', isPositive: true },
    { name: 'Sustainable buildings', emoji: '🏡', tag: '🏙️ Sustainable buildings', isPositive: true },
    { name: 'Factory farming', emoji: '🐓', tag: '🐓 Factory farming ', isPositive: false },
    {
        name: 'Junk foods and highly processed foods',
        emoji: '🍔',
        tag: '🍔 Junk foods and highly processed foods ',
        isPositive: false,
    },
    { name: 'Alcohol', emoji: '🍺', tag: '🍺 Alcohol ', isPositive: false },
    { name: 'Live animal export', emoji: '🚢', tag: '🚢 Live animal export', isPositive: false },
    { name: 'Tobacco', emoji: '🚬', tag: '🚬 Tobacco ', isPositive: false },
    { name: 'Gambling', emoji: '🎲', tag: '🎲 Gambling', isPositive: false },
    { name: 'Uranium and nuclear', emoji: '☢️', tag: '☢️ Uranium and nuclear', isPositive: false },
    { name: 'Fossil fuel electricity', emoji: '🏭', tag: '🏭 Fossil fuel electricity  ', isPositive: false },
    { name: 'Palm oil', emoji: '🌴', tag: '🌴 Palm oil', isPositive: false },
    { name: 'Weapons and military services', emoji: '💣', tag: '💣 Weapons and military services', isPositive: false },
    { name: 'Single use plastics', emoji: '🥤', tag: '🥤Single use plastics', isPositive: false },
    { name: 'Fossil fuels', emoji: '🛢️', tag: '🛢️ Fossil Fuels', isPositive: false },
]

const POSITIVE_FILTERS = FILTERS.filter((filter) => filter.isPositive)
const NEGATIVE_FILTERS = FILTERS.filter((filter) => !filter.isPositive)

export function Trading() {
    const trendingCarouselRef = useRef<HTMLDivElement>(null)
    const [canScrollLeft, setCanScrollLeft] = useState(false)
    const [canScrollRight, setCanScrollRight] = useState(false)

    const { showTradingPage } = useFeatureFlags()
    const [positiveFilterTags, setPositiveFilterTags] = useState<string[]>([])
    const [negativeFilterTags, setNegativeFilterTags] = useState<string[]>([])
    const { data: trendingSecurities, isLoading: isLoadingTrending } = useFetchTrendingSecurities()
    const [filteredSecurities, setFilteredSecurities] = useState(trendingSecurities)
    const {
        data: securitiesByTag,
        refetch: refetchSecuritiesByTag,
        isLoading,
    } = useFetchSecuritiesByTags(positiveFilterTags)

    const positiveFilterOptions = POSITIVE_FILTERS.map((filter) => ({
        label: filter.tag,
        value: filter.tag,
    }))
    const handlePositiveFilters = (selectedFilters: string[]) => {
        selectedFilters.forEach((filter) => {
            analytics.track('TradingFilterClicked ', {
                filter,
            })
        })
        setPositiveFilterTags(selectedFilters)
    }
    const handleSelectAllPositiveFilters = () => {
        analytics.track('TradingFilterClicked ', {
            filter: 'Select All Positive Filters',
        })
    }

    const negativeFilterOptions = NEGATIVE_FILTERS.map((filter) => ({
        label: filter.tag,
        value: filter.tag,
    }))
    const handleNegativeFilters = (selectedFilters: string[]) => {
        selectedFilters.forEach((filter) => {
            analytics.track('TradingFilterClicked ', {
                filter,
            })
        })
        setNegativeFilterTags(selectedFilters)
    }
    const handleSelectAllNegativeFilters = () => {
        analytics.track('TradingFilterClicked ', {
            filter: 'Select All Negative Filters',
        })
    }

    useEffect(() => {
        refetchSecuritiesByTag()
    }, [positiveFilterTags, refetchSecuritiesByTag])

    useEffect(() => {
        const updatedSecurities = securitiesByTag?.filter(
            (security: any) =>
                !negativeFilterTags.some((tag) => security.ethicalTags.some((et: any) => et.tag === tag)) &&
                security.securityDetails &&
                security.securityDetails.priceInfo &&
                !isNaN(security.changeInPriceYTD),
        )
        setFilteredSecurities(updatedSecurities)
    }, [trendingSecurities, securitiesByTag, negativeFilterTags, positiveFilterTags])

    const checkScrollPosition = () => {
        if (trendingCarouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = trendingCarouselRef.current
            setCanScrollLeft(scrollLeft > 0)
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth)
        }
    }

    const scroll = (direction: 'left' | 'right') => {
        if (trendingCarouselRef.current) {
            const { clientWidth } = trendingCarouselRef.current
            let scrollAmount = clientWidth / 2
            if (direction === 'left') scrollAmount *= -1
            trendingCarouselRef.current.scrollBy({
                left: scrollAmount,
                behavior: 'smooth',
            })
        }
    }

    useEffect(() => {
        checkScrollPosition()
        const container = trendingCarouselRef.current
        if (container) {
            container.addEventListener('scroll', checkScrollPosition)
            return () => container.removeEventListener('scroll', checkScrollPosition)
        }
    }, [isLoadingTrending])

    if (isLoadingTrending) return <FullScreenLoader />

    if (!showTradingPage) return null

    return (
        <div>
            <Helmet>
                <title>Trading | {APP.title}</title>
            </Helmet>

            <div className="bg-[#f9f9f9] relative pt-6 -ml-6 md:-ml-0 -mr-6 md:-mr-12">
                <div className="px-3 md:px-6 flex items-center gap-2 mb-8">
                    <h3 className="text-3xl">🔥 Trending ETFs</h3>
                    <div>
                        <InfoIconTooltip>
                            The companies listed below were selected by SIX based on being the largest sustainability
                            Exchange-Traded Funds (ETFs) on the Australian Stock Exchange (ASX) and are not a
                            recommendation to buy or sell these shares. Please ensure you conduct your own research
                            before making any investment decisions.
                        </InfoIconTooltip>
                    </div>
                </div>
                <div
                    className="gap-2 overflow-auto px-3 md:px-6 pb-6 flex items-stretch overflow-x-auto"
                    ref={trendingCarouselRef}
                >
                    {trendingSecurities?.map((security: any) => {
                        const price =
                            security.securityDetails.priceInfo.buyPrice ??
                            security.securityDetails.priceInfo.closingPrice
                        return (
                            <Link
                                key={security.securityDetails.securityCode}
                                className="text-current group flex-none"
                                to={`${APP_ROUTES.SECURITY}/${security.securityDetails.securityCode}`}
                                target="_blank"
                                onClick={() =>
                                    analytics.track('TradingPageSecurityCard Clicked', {
                                        securityName:
                                            security.securityDetails.companyName ??
                                            security.securityName ??
                                            security.securityDetails.securityName,
                                        securityCode: security.securityDetails.securityCode,
                                        price:
                                            security.securityDetails.priceInfo.buyPrice ??
                                            security.securityDetails.priceInfo.closingPrice,
                                        changeInPriceYTD: security.changeInPriceYTD,
                                        revenueSource: security.ethicalTags.map((et: any) => et.tag),
                                        numPositiveTags: security.ethicalTags.reduce(
                                            (acc: number, curr: any) => (curr.isTagPositive ? acc + 1 : 0),
                                            0,
                                        ),
                                        numNegativeTags: security.ethicalTags.reduce(
                                            (acc: number, curr: any) => (curr.isTagPositive ? 0 : acc + 1),
                                            0,
                                        ),
                                    })
                                }
                            >
                                <SecuritySnapshot
                                    securityCode={security.securityDetails.securityCode}
                                    securityName={
                                        security.securityDetails.companyName ??
                                        security.securityName ??
                                        security.securityDetails.securityName
                                    }
                                    price={price}
                                    changeInPriceYTD={security.changeInPriceYTD}
                                    historicalPrices={security.historicalPrices}
                                />
                            </Link>
                        )
                    })}
                </div>
                {canScrollLeft ? (
                    <button
                        onClick={() => scroll('left')}
                        className="absolute left-2 rounded-full border-2 p-1 top-1/2 -transform-y-1/2 bg-background"
                    >
                        <ArrowLeft />
                    </button>
                ) : null}
                {canScrollRight ? (
                    <button
                        onClick={() => scroll('right')}
                        className="absolute right-2 rounded-full border-2 p-1 top-1/2 -transform-y-1/2 bg-background"
                    >
                        <ArrowRight />
                    </button>
                ) : null}
            </div>

            <div className="border-b py-6 px-3 md:px-6">
                <h1 className="text-4xl mb-8 md:text-40 font-semibold">Trading</h1>
                <div className="flex flex-col gap-2 sm:flex-row md:gap-6">
                    <MultiSelect
                        maxCount={1}
                        label="✅ Include:"
                        options={positiveFilterOptions}
                        onValueChange={handlePositiveFilters}
                        onSelectAll={handleSelectAllPositiveFilters}
                    />
                    <MultiSelect
                        maxCount={1}
                        label="❌ Exclude:"
                        options={negativeFilterOptions}
                        onValueChange={handleNegativeFilters}
                        onSelectAll={handleSelectAllNegativeFilters}
                    />
                </div>
            </div>

            {isLoading ? (
                <FullScreenLoader />
            ) : filteredSecurities?.length ? (
                <div className="px-3 md:px-6">
                    <div className="text-black text-sm font-semibold font-['Inter'] leading-tight pt-6">
                        {filteredSecurities?.length} results
                    </div>
                    <div className="grid mt-5 grid-wrap grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                        {filteredSecurities?.map((security: any) => {
                            const price =
                                security.securityDetails.priceInfo.buyPrice ??
                                security.securityDetails.priceInfo.closingPrice
                            return (
                                <Link
                                    key={security.securityDetails.securityCode}
                                    className="w-full text-current hover:underline"
                                    to={`${APP_ROUTES.SECURITY}/${security.securityDetails.securityCode}`}
                                    target="_blank"
                                    onClick={() =>
                                        analytics.track('TradingPageSecurityCard Clicked', {
                                            securityName:
                                                security.securityDetails.companyName ??
                                                security.securityName ??
                                                security.securityDetails.securityName,
                                            securityCode: security.securityDetails.securityCode,
                                            price:
                                                security.securityDetails.priceInfo.buyPrice ??
                                                security.securityDetails.priceInfo.closingPrice,
                                            changeInPriceYTD: security.changeInPriceYTD,
                                            revenueSource: security.ethicalTags.map((et: any) => et.tag),
                                            numPositiveTags: security.ethicalTags.reduce(
                                                (acc: number, curr: any) => (curr.isTagPositive ? acc + 1 : 0),
                                                0,
                                            ),
                                            numNegativeTags: security.ethicalTags.reduce(
                                                (acc: number, curr: any) => (curr.isTagPositive ? 0 : acc + 1),
                                                0,
                                            ),
                                        })
                                    }
                                >
                                    <SecurityCard
                                        loading={isLoading}
                                        securityCode={security.securityDetails.securityCode}
                                        securityName={
                                            security.securityDetails.companyName ??
                                            security.securityName ??
                                            security.securityDetails.securityName
                                        }
                                        price={price}
                                        changeInPriceYTD={security.changeInPriceYTD}
                                        historicalPrices={security.historicalPrices}
                                        ethicalTags={security.ethicalTags}
                                        withEthicalTags
                                    />
                                </Link>
                            )
                        })}
                    </div>
                </div>
            ) : !positiveFilterTags.length ? (
                <div className="text-foreground text-center flex flex-col gap-12 py-12 items-center">
                    <TradingBlankState />
                    <div className="max-w-sm flex flex-col gap-2">
                        <H3>Ready to build a portfolio that matches your values?</H3>
                        <p>Select from the filters above to discover investments you care about.</p>
                    </div>
                </div>
            ) : (
                <div className="text-foreground text-center flex flex-col gap-2 py-6">
                    <H3>No exact matches</H3>
                    <p>Try changing or removing some of your filters.</p>
                </div>
            )}
        </div>
    )
}
